.attribute-wrapper {
  background-color: #fff;
  padding: 28px 10px;
  border-radius: 10px;

  &.error {
    background-color: rgba(224, 48, 0, 0.08);
  }
  .radioItem {
    background: #dddddd;
    border-radius: 50px;
    margin-bottom: 5px;
    height: 55px;
  }
  .radioItemChecked {
    background: #11117578 !important;
  }
  .radioItemChecked label {
    color: white !important;
  }
}

.add-on-text {
  font-weight: 700;
  font-size: 18px;
  color: #22262d;
}

.option-text {
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  color: #64676c;
}
.addOn-grid {
  // maxWidth: '1200px',
  // margin: '0 auto',
  display: grid;
  align-items: center;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  @media (max-width: '320px') {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
.addOn-drop-down {
  width: -webkit-fill-available;
  border-radius: 10px;
  padding: 10px;
  height: 40px;
  font-size: 14px;
  color: #22262d !important;
  :first-child {
    display: none;
  }
}
// .options-bubble-grid {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 5px;
//   place-content: center;
//   place-items: start stretch;
// }
.options-bubble-grid {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: flex-start;
  align-items: center;
}
.row-item {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.restaurant-detail-content {
  padding: 28px 15px;
  background-color: #fff;
  margin-bottom: 15px;

  .text-title {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: -0.02em;
    color: #2b2b2b;
  }

  .menu-rating-price {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rating-description {
      display: flex;
      align-items: center;
      -moz-column-gap: 9px;
      column-gap: 9px;

      .rating {
        font-weight: 700;
        font-size: 16px;
      }

      .people-ordered-text {
        font-weight: 400;
        font-size: 16px;
        color: #22262d;
        display: flex;
        align-items: center;

        &::before {
          content: ' ';
          border-radius: 50%;
          background-color: #afafaf;
          height: 4px;
          width: 4px;
          margin-right: 8px;
          display: inline-block;
        }
      }
    }

    .price {
      font-size: 18px;
      font-weight: 700;
      color: #22262d;
    }
  }

  .detail-text {
    font-weight: 400;
    font-size: 14px;
    color: #64676c;
  }

  .add-and-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rating-dish-text {
      font-weight: 700;
      font-size: 16px;
      color: #dd5f36;
    }
  }
}
