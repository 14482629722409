.overlay-image {
  position: absolute;
  background: linear-gradient(0deg, #000000 2.01%, rgba(0, 0, 0, 0) 67.58%);
  border-radius: 10px;
  opacity: 0.6;
  width: 100%;
  z-index: 1;
  height: 100%;
}

.thumbnail-pricing {
  font-weight: 700;
  font-size: 17px;
  line-height: 14px;
  color: #22262d;
  white-space: nowrap;
  gap: 6px;
  .not-available {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--cl-grey);
    @media screen and (max-width: 280px) {
      font-size: 11px;
      line-height: 13px;
    }
  }
  @media screen and (max-width: 280px) {
    font-size: 12px;
    line-height: 10px;
  }
}

.thumbnail-wrapper {
  position: relative;
  width: 100%;
  max-width: 273px;
  height: 275px;
  border-radius: 10px;
  border: 0;
  flex: 0 0 45.5%;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);

  .menu-image-wrapper {
    position: relative;

    .menu-image {
      width: 100%;
      border-radius: 10px;
      height: 100%;
      min-height: 180px;
    }
  }

  .thumbnail-content {
    width: 100%;
    height: auto;
    background-color: var(--cl-white);
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    padding: 12.88px 10px 20px 14px;

    .thumbnail-rating {
      display: flex;
      -moz-column-gap: 5px;
      column-gap: 5px;
      position: absolute;
      top: -29px;

      .rating {
        font-weight: 600;
        font-size: 16px;
        color: var(--cl-white);
      }
    }

    .thumbnail-title {
      font-weight: 400;
      font-size: 16px;
      color: var(--cl-black);
    }
  }

  .thumbnail-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.thumbnail-old-pricing {
  text-decoration: line-through !important;
  color: darkgray !important;
  font-size: 18px !important;
  margin: 0px 0px 0px 10px;
  @media screen and (max-width: 280px) {
    font-size: 12px !important;
  }
}

.thumbnail-horizontal-wrapper {
  grid-template-columns: 131px auto;
  background-color: var(--cl-white);
  border-radius: 10px;
  min-height: 132px;
  // max-width: 150px;
  max-width: min-content;

  div .overlay-image {
    width: 100%;
    height: 150px;
  }

  .thumbnail-image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    min-width: 150px;
    min-height: 150px;
    // max-width: 150px;
    max-height: 150px;
  }

  .thumbnail-rating {
    position: absolute;
    bottom: 13px;
    left: 15px;
    color: var(--cl-white);
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    -moz-column-gap: 6px;
    column-gap: 6px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  .thumbnail-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 5px 12px 5px;
  }

  .title {
    // font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--cl-black);
    min-height: 45px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--cl-grey);
  }
  .not-available-overlay {
    position: absolute;
    background: linear-gradient(0deg, #ffffff 100%, rgb(198 198 198 / 0%) 100%);
    border-radius: 10px;
    opacity: 0.6;
    width: 100%;
    z-index: 1;
    height: 15rem;
  }
}

.thumbnail-landscape-wrapper {
  display: grid;
  grid-template-columns: 131px auto;
  background-color: var(--cl-white);
  border-radius: 10px;
  min-height: 132px;
  @media screen and (max-width: 280px) {
    min-height: 100px;
    grid-template-columns: 100px auto;
  }
  .image-container {
    position: relative;
    @media screen and (max-width: 280px) {
      max-height: 100px;
      max-width: 100px;
    }
  }
  &.transparent {
    background-color: transparent;
  }

  .thumbnail-image {
    height: 100%;
    width: 100%;
    max-height: 140px;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0px;
  }

  .thumbnail-rating {
    position: absolute;
    bottom: 13px;
    left: 15px;
    color: var(--cl-white);
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    -moz-column-gap: 6px;
    z-index: 4;
    column-gap: 6px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  .thumbnail-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    @media screen and (max-width: 280px) {
      padding: 7px;
      max-width: 125px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--cl-black);

    @media screen and (max-width: 280px) {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--cl-grey);
    @media screen and (max-width: 280px) {
      font-size: 11px;
      line-height: 13px;
    }
  }

  .not-available-overlay {
    position: absolute;
    background: linear-gradient(0deg, #ffffff 100%, rgb(198 198 198 / 0%) 100%);
    border-radius: 10px;
    opacity: 0.6;
    width: 100%;
    z-index: 1;
    height: 100%;
  }
}
