.footer-wrapper {
  position: fixed;
  left: 0;
  bottom: -1px;
  width: 100%;
  padding: 31px 25px;
  background-color: #fff;
  box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 0px 0px;
  position: -webkit-sticky;
  display: flex;
  z-index: 5;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 280px) {
    gap: 10px;
    padding: 10px 10px;
    justify-content: center;
  }

  .basket-wrapper {
    font-weight: 700;
    justify-content: space-around;
    width: 160px;
    height: 56px;
    @media screen and (max-width: 280px) {
      width: 135px;
      height: 50px;
    }
    white-space: nowrap;
    display: flex;
    align-items: center;
    background: #dd5f36;
    color: #fff;
    border-radius: 12px;
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    box-shadow: 0px 12px 20px rgba(221, 95, 54, 0.4);
    .basket-box {
      display: flex;
      padding: 8px;
      align-items: center;
      border-radius: 12px;
      background-color: #fff;
    }
  }

  .basket-wrapper-disabled {
    justify-content: space-around;
    width: 180px;
    height: 56px;
    @media screen and (max-width: 280px) {
      width: 135px;
      height: 50px;
    }
    display: flex;
    align-items: center;
    background: #707070;
    color: #fff;
    border-radius: 12px;
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    box-shadow: 0px 4px 13px 0px #d9d9d9;

    .basket-box {
      display: flex;
      padding: 8px;
      align-items: center;
      border-radius: 12px;
      background-color: #fff;
    }
  }

  .basket-wrapper-block {
    display: flex;
    align-items: center;
    background: #707070;
    color: #fff;
    border-radius: 12px;
    padding: 10px;
    font-weight: 700;
    font-size: 18px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    box-shadow: 0px 4px 13px 0px #d9d9d9;

    .basket-box {
      display: flex;
      padding: 8px;
      align-items: center;
      border-radius: 12px;
      background-color: #fff;
    }
  }

  .footer-price {
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: -0.02em;
    @media screen and (max-width: 280px) {
      font-size: 20px;
    }
  }

  .footer-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #22262d;
    @media screen and (max-width: 280px) {
      font-size: 10px;
    }
  }
}
