.bottom-sheet-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #2b2b2b;
}

.bottom-sheet-menu-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #22262d;
}

.edit-text {
  color: var(--cl-primary);
  font-weight: 700;
  font-size: 16px;
}

.menu-wrapper {
  border-bottom: 1px solid #d3d4d5;

  &:not(:first-child) {
    padding-top: 22px;
  }

  .attributes {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #64676c;
  }

  .circles-add {
    position: relative;
    width: 14.67px;
    border: 2px solid#64676C;
    border-radius: 50%;
    height: 14.67px;

    &::before {
      content: '+';
      text-align: center;
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.pd-12 {
  padding: 12px;
}

.bottom-sheet-wrapper {
  position: fixed;
  bottom: 0%;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background: rgba(34, 38, 45, 0.8);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
  .language-title {
    color: #22262d;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 25px;
  }
  .language-text {
    color: #64676c;
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
  }
  .header {
    background-color: var(--cl-white);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    position: relative;
    padding: 29px 25px;
    @media screen and (max-width: 280px) {
      padding: 29px 10px;
    }
  }

  .content {
    padding: 38px 25px;
  }

  .total-amount-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 15px;
    text-align: right;
    color: #22262d;
  }

  .bottom-btn {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    background-color: var(--cl-white);
    box-shadow: 0px -6px 30px rgba(0, 0, 0, 0.15);
    border-radius: 20px 20px 0px 0px;

    .padding {
      padding: 25px;
      padding-bottom: 10px;
      @media screen and (max-width: 280px) {
        padding: 10px;
        .contact-info {
          font-size: 13px;
        }
      }
    }

    .order-description {
      background-color: var(--cl-bg);
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: var(--cl-black);
      padding: 10px;
      padding-top: 0px;
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    bottom: 0;
  }
}

.bottom-sheet-review-order {
  background-color: var(--cl-white);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  border-radius: 0;
  overflow: scroll;
  max-height: 100%;
}

.bottom-sheet-content {
  background-color: var(--cl-white);
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  width: 100%;
  bottom: 0;
  overflow: scroll;
  max-height: 98%;
  -webkit-animation: slideUp 0.3s ease-out;
  animation: slideUp 0.3s ease-out;

  &.padding {
    padding: 24px 27px;
    @media screen and (max-width: 280px) {
      padding: 10px 15px;
    }
  }
}
.language-divider {
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 38px;
  margin-top: 20px;
}
.call-for-waiter-input {
  padding: 16px 17px;
  border: 1px solid #d3d4d5;
  border-radius: 10px;
  width: 100%;
  margin-top: 13px;
}

@-webkit-keyframes slideUp {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
