.input {
  padding: 16px 17px;
  border: 1px solid #D3D4D5;
  border-radius: 10px;
  width: 100%;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--cl-txt-placeholder);
  }
}

.check-box {
  border: 2px solid var(--cl-black);
}

.check-box[type=checkbox],
.check-box[type=radio] {
  accent-color: var(--cl-primary);
}