.quantity-wrapper {
  display: flex;
  align-items: center;
  -moz-column-gap: 20px;
  column-gap: 20px;

  .item-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    background-color: var(--cl-black);
    color: var(--cl-white);
    border-radius: 3px;
    width: 40px;
    height: 40px;
  }

  .title {
    font-size: 26px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: var(--cl-black);
    padding: 0px 7px;
  }
}

.small-quantity {
  -moz-column-gap: 10px;
  column-gap: 15px;

  .item-box {
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 0;
  }

  .title {
    font-size: 16px;
  }
}
