.pickup-information {
  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
  }
  .autocomplete-list {
    padding: 2px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 5px;
    .autocomplete-option {
      padding: 10px;
      border-radius: 10px;
      background-color: #f5f5f5;
      cursor: pointer;
      &:hover {
        background-color: #e5e5e5;
      }
    }
  }
  .pickup-information-item {
    row-gap: 5px;
    margin-bottom: 16px;
    .address-input-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .address-input {
        width: 100%;
        // margin-right: 14px;
      }
      .icon-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 50px;
        border-radius: 10px;
        background-color: #f5f5f5;
        cursor: pointer;
      }
    }

    .label {
      font-weight: 500;
    }

    .phone-country-code {
      width: 120px;

      margin-right: 14px;

      input {
        text-align: center;
      }
    }

    .select {
      font-size: 1rem;
      padding: 16px;
      border-radius: 10px;
      border: 1px solid #d3d4d5;
    }
    .error {
      color: red;
      white-space: nowrap;
    }
  }
}
.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

