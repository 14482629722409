.btn {
  width: 100%;
  height: 100%;
  text-align: center;
  box-shadow: 0px 12px 20px rgba(221, 95, 54, 0.4);
  border-radius: 12px;
  font-weight: 700;
  font-size: 18px;

  &.primary {
    background-color: var(--cl-primary);
    color: var(--cl-white);
    border: 1px solid var(--cl-primary);
    padding: 12px 20px;
    box-shadow: 0px 12px 20px rgba(221, 95, 54, 0.4);
    @media screen and (max-width: 280px) {
      padding: 12px 10px;
    }
  }

  &.light-active {
    border: 1px solid var(--cl-purple);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    background-color: var(--cl-white);
    color: var(--cl-purple);
    padding: 10px 0;
    font-size: 16px;
  }

  &.light-primary {
    border: 1px solid var(--cl-primary);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12.7273px;
    background-color: var(--cl-white);
    color: var(--cl-primary);
    padding: 22px 0;
    font-weight: 700;
  }
}
