@import 'fonts';
@import 'menu_listing';
@import 'restaurant-detail';
@import 'thumbnail';
@import 'layout';
@import 'core';
@import 'order-summary';
@import './pages/pages';
@import './components/partials/partials';
@import 'pickup-information';

* {
  box-sizing: border-box;
  margin: 0;
  // font-family: 'Kantumruy', 'Gilroy-Medium', 'SF Pro Display', sans-serif;
  font-family: 'Kantumruy Pro', sans-serif;
  letter-spacing: -0.02em;
  max-width: 120vw;
}

body {
  background-color: var(--cl-bg);
  max-width: 120vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  // width: 100%;
}

.position-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.not-scroll {
  overflow-y: hidden;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paragraph-truncate {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin-bottom: 10px;
}

.blur-background {
  background: rgba(34, 38, 45, 0.8);
  -webkit-backdrop-filter: blur(2.5px);
  backdrop-filter: blur(2.5px);
}

.labels {
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #000000;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.flex-row {
  display: flex;
  align-items: center;
  &.align-start {
    align-items: flex-start;
  }

  &.column-gap-4 {
    -moz-column-gap: 4px;
    column-gap: 4px;
  }

  &.column-gap-7 {
    -moz-column-gap: 7px;
    column-gap: 7px;
  }

  &.column-gap-10 {
    -moz-column-gap: 10px;
    column-gap: 10px;
  }

  &.column-gap-16 {
    -moz-column-gap: 16px;
    column-gap: 16px;
  }

  &.column-gap-20 {
    -moz-column-gap: 20px;
    column-gap: 20px;
  }

  &.column-gap-14 {
    -moz-column-gap: 14px;
    column-gap: 14px;
  }

  &.column-gap-15 {
    -moz-column-gap: 15px;
    column-gap: 15px;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.pt {
  &-48 {
    padding-top: 48px;
  }

  &-28 {
    padding-top: 28px;
  }

  &-23 {
    padding-top: 23px;
  }

  &-22 {
    padding-top: 22px;
  }

  &-12 {
    padding-top: 12px;
  }

  &-10 {
    padding-top: 10px;
  }

  &-0 {
    padding-top: 0;
  }
}

.ml {
  &-24 {
    margin-left: 24px;
  }

  &-14 {
    margin-left: 14px;
  }

  &-10 {
    margin-left: 10px;
  }

  &-22 {
    margin-left: 22px;
  }

  &-6 {
    margin-left: 6px;
  }
}

.mt {
  &-86 {
    margin-top: 86px;
  }

  &-49 {
    margin-top: 49px;
  }

  &-48 {
    margin-top: 48px;
  }

  &-40 {
    margin-top: 40px;
  }

  &-35 {
    margin-top: 35px;
  }

  &-33 {
    margin-top: 33px;
  }

  &-30 {
    margin-top: 30px;
  }

  &-28 {
    margin-top: 28px;
  }

  &-27 {
    margin-top: 27px;
  }

  &-26 {
    margin-top: 26px;
  }

  &-24 {
    margin-top: 24px;
  }

  &-22 {
    margin-top: 22px;
  }

  &-20 {
    margin-top: 20px;
  }

  &-19 {
    margin-top: 19px;
  }

  &-16 {
    margin-top: 16px;
  }

  &-15 {
    margin-top: 15px;
  }

  &-14 {
    margin-top: 14px;
  }

  &-12 {
    margin-top: 12px;
  }

  &-11 {
    margin-top: 11px;
  }

  &-10 {
    margin-top: 10px;
  }

  &-6 {
    margin-top: 6px;
  }
}

.mb {
  &-25 {
    margin-bottom: 25px;
  }

  &-24 {
    margin-bottom: 24px;
  }

  &-22 {
    margin-bottom: 22px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-19 {
    margin-bottom: 19px;
  }

  &-15 {
    margin-bottom: 15px;
  }

  &-11 {
    margin-bottom: 11px;
  }

  &-10 {
    margin-bottom: 10px;
  }

  &-6 {
    margin-bottom: 6px;
  }
}
