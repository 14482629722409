:root {
  //
  --cl-primary: #DD5F36;
  --cl-grey: #64676C;
  --cl-bg: #F4F4F4;

  // Color
  --cl-black: #22262D;
  --cl-light-grey: #AFAFAF;
  --cl-white: #FFFF;
  --cl-purple: #4267B2;

  // Text
  --cl-txt: #2B2B2B;
  --cl-txt-placeholder: #909296;
}