.content-wrapper {
  margin: 72px 20px;

  .content-card {
    position: relative;
    background-color: var(--cl-white);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .restaurant-profile {
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: -40px;
    z-index: 2;

    img {
      width: 70px;
      border: 1px solid #f4f4f4;
      height: 70px;
      border-radius: 50%;
    }
  }

  .section {
    padding: 30px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #d3d4d5;
    }
  }

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--cl-black);
  }

  .date {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--cl-txt-placeholder);
  }
  .socials {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .price-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: #64676c;
  }

  .total-amount-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 15px;
    text-align: right;
    color: #22262d;
  }
}
.contact-us-wrapper {
  background-color: #f4f4f4;
  .contact-us-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    padding: 20px;
    align-content: center;
    align-items: center;
    border-bottom: 2px solid #e5e5e5;
    background-color: #ffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    .contact-us-header-title {
      font-size: 26px;
      font-weight: bold;
      color: #2b2b2b;
    }
  }
  .contact-us-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    .contact-us-cards {
      background-color: #d9d9d9;
      height: 15vh;
      width: 100%;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      padding: 15px;
      box-shadow: 0px 4px 4px #d9d9d9;
      .contact-us-cards-content {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        height: 100%;
        .contact-us-follow-us-on {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
        }
        .contact-us-follow-wrapper {
          height: 100%;
          display: flex;
          align-items: flex-end;
          .contact-us-follow-button {
            width: 78px;
            height: 30px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: bold;
            font-size: 12px;
          }
        }
      }
    }
  }
}
