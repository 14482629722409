.pop-up {
  position: fixed;
  z-index: 9999;
}

.location-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.5);
  // background-color: blue;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-container {
  width: 100%;
  // height: 500px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.location-container .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #545454;
    }
    .sub-title {
      font-size: 15px;
      font-weight: 300;
      color: #545454;
    }
    .wifi-details {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border: 1px solid #c7c7c7;
      padding: 10px;
      border-radius: 10px;
      width: 100%;
    }
  }
}

.location-container .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-container .footer button {
  width: 100px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: #808080;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}
