.order-summary-header {
  background-color: #fff;
  padding: 26px 15px;

  .add-order {
    color: var(--cl-primary);
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
  }
}

.total-amount-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 15px;
  text-align: right;
  color: #22262d;
}

.mb-5 {
  margin-bottom: 5px;
}

.flex-1 {
  flex: 1;
}

.flex-5 {
  flex: 5;
}

.flex-12 {
  flex: 12;
}

.order-summary-wrapper {
  padding: 28px 0px 19px 15px;
}

.order-summary-label {
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: var(--cl-txt);
}

.order-summary-empty {
  margin: 0 70px;
  position: absolute;
  left: 0;
  right: 0;
  top: 32%;
  text-align: center;

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--cl-black);
  }
}

.order-received-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--cl-txt-placeholder);
}

.order-item-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: var(--cl-txt);
}

.order-price {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  text-align: right;
  color: #22262d;
  flex-grow: 1;

  .thumbnail-old-pricing {
    margin: 0px 10px 0px 0px;
  }
}

.order-summary {
  border-bottom: 1px solid #d3d4d5;
  padding-bottom: 20px;

  &:nth-child(n + 1) {
    margin-top: 22px;
  }

  .addOns-items {
    flex-direction: column;
  }
}

.quantity-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: right;
  color: #64676c;
}

.order-content-wrapper {
  background-color: #fff;
  padding: 26px 29px 28px 31px;
  border-radius: 10px;

  .flex {
    display: flex;

    &.column {
      flex-direction: column;
    }
  }

  .order-item-option {
    font-size: 14px;
    line-height: 100%;
    color: var(--cl-grey);
  }
}

.margin-on-last-child {
  .child:last-child {
    padding-bottom: 28%;
  }
}

.order-summary-bottom {
  background: #ffffff;
  box-shadow: 0px -6px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px;
  padding: 25px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;

  .divider {
    border-top: 1px solid #d3d4d5;
  }

  .total-label {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: var(--cl-txt);
  }

  .pricing-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    color: var(--cl-txt);
  }

  .sub-summary-label {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    color: var(--cl-txt);
  }
}

.call-stuff-for-bill-wrapper {
  transition: opacity 0.5s;
  width: 100%;
  position: inherit;
  height: 100%;

  &.blur-background {
    position: inherit;
  }

  .overlay {
    height: 50%;
    width: 100%;
  }

  &.opacity {
    opacity: 1 !important;
    top: 0;
    z-index: 999999;
    position: fixed;
  }

  .content {
    padding: 36px 25px;
    background-color: #fff;
    box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px 20px 0px 0px;
    position: fixed;
    bottom: 0;
    width: 100%;

    .your-payment-method-txt {
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: var(--cl-txt);
    }
    .note-textarea {
      margin-top: 10px;
      width: 100%;
      height: 100px;
      border: 1px solid #d3d4d5;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;
      line-height: 100%;
      color: var(--cl-grey);
    }
  }
}
