.navigation-wrapper {
  display: flex;
  flex-direction: column;

  .navigation-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #22262d;
  }

  .navigation-item {
    width: 100%;

    &:not(:first-child) {
      padding-top: 20px;
    }

    &:not(:last-child) {
      padding-bottom: 20px;
      border-bottom: 1px solid #d3d4d5;
    }
  }

  .quick-order-header-title {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    font-size: 22px;
    white-space: nowrap;
  }
  .quick-order-header-confirmation {
    font-size: 14px;
    margin-bottom: 15px;
    .menu-name {
      font-weight: 450;
    }
  }
  .quick-order-divider {
    width: 100%;
    border-bottom: 3px dotted #d7d7d7;
    margin-bottom: 20px;
  }
  .quick-order-pt-15 {
    padding-top: 15px;
  }
  .quick-order-addons {
    padding-bottom: 10;
    align-items: flex-start;
    .quick-order-quantity {
      font-weight: 600;
    }
    .quick-order-price {
      white-space: nowrap;
    }
  }
  .quick-order-button-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 5px;
    .quick-order-button-close {
      background-color: #ed4d4d;
      box-shadow: 0px 4px 4px #ed4d4d 40;
      border-color: #ed4d4d;
    }
  }
}
