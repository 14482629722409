.rating-header {
  padding: 28px 30px;
  background-color: var(--cl-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  .skip-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: var(--cl-primary);
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #2b2b2b;
  }
}

.rating-section {
  background-color: var(--cl-white);
  padding: 23px 21px;
  margin-top: 17px;
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: 17px;
  }

  &.error {
    background-color: rgba(224, 48, 0, 0.08);
  }

  .rating-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #22262d;
  }

  .rating-textarea {
    resize: none;
    width: 100%;
    border: 1px solid #d3d4d5;
    border-radius: 10px;
    height: 100;
    padding: 22px 20px;
  }
}

.rating-button {
  margin: 42px 25px 25px 25px;
}

.star-list {
  .rating-star {
    cursor: pointer;
    fill: none;

    &:hover ~ .rating-star {
      fill: none;
    }
  }

  &:hover .rating-star {
    fill: #faba39;
  }
}

svg:hover {
  fill: #faba39;
}
