.not-found-wrapper {
  margin-top: 120px;
  padding: 0 25px;

  .center {
    text-align: center;
  }

  .somethings-went-wrong-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #22262D;
  }

  .image-404 {
    width: 100%;
    height: 100%;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #64676C;
  }

}