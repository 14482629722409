.restaurant-profile-header-container {
  background: var(--cl-white);
  position: relative;
  height: 367px;
  max-width: 100vw;

  .hamburger-wrapper {
    position: absolute;
    right: 15px;
    top: -120%;
    height: 289px;
    z-index: 100;
  }

  .logo-wrapper {
    position: absolute;
    left: 15px;
    top: -7%;
    height: 68%;
    z-index: 100;
  }

  .title-sticky {
    position: absolute;
    left: 25%;
    height: 70px;
    @media screen and (max-width: 280px) {
      left: 32%;
    }
  }

  .profile-image-logo {
    width: 70px;
    height: 70px;
    z-index: 5;
    border-radius: 50%;
    border: 1px solid var(--cl-bg);
  }

  .restaurant-title {
    font-weight: 600;
    font-size: 22px;
    color: var(--cl-black);
    @media screen and (max-width: 280px) {
      font-size: 20px;
    }
  }

  .restaurant-information {
    width: 100%;
    background: var(--cl-white);
    border-radius: 0px 0px 20px 20px;
    min-height: 23vh;

    &.padding {
      padding: 8px 15px 21px 15px;
    }
  }

  .absolute-bottom {
    position: absolute;
    // bottom: 21px;
    bottom: 50px;
    left: 15px;
    right: 15px;
  }

  .sticky-navigation {
    position: absolute;
    right: 0;
    top: 0;
  }

  .stick-profile-row {
    height: 109px;
  }

  .menu-filter-box {
    padding: 15px 17px;
    display: flex;
    max-height: 56px;
    align-items: center;
    justify-content: center;
    border: 1px solid #d3d4d5;
    border-radius: 10px;
    background: var(--cl-white);
    @media screen and (max-width: 280px) {
      padding: 15px 12px;
    }
  }

  .menu-filter-wrapper {
    display: flex;
    align-items: flex-end;
    -moz-column-gap: 10px;
    column-gap: 10px;
    margin-top: 20px;
  }
  .outlet-details-header {
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 5px;

    .outlet-details-list {
      display: flex;
      align-items: center;
      .details-text {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #a9a9a9;
      }
    }
  }
  .slide-in-right {
    animation: slide-in-blurred-left 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  }
  @keyframes slide-in-blurred-left {
    0% {
      transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
      transform-origin: 100% 50%;
      filter: blur(40px);
      opacity: 0;
    }
    100% {
      transform: translateX(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
      filter: blur(0);
      opacity: 1;
    }
  }
  .restaurant-wallpaper {
    width: 100%;
    max-width: 100%;
    height: 234px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .wallpaper {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0px;
    }

    .profile-wrapper {
      position: absolute;
      background-color: var(--cl-white);
      padding: 19px;
      border-radius: 10px;
      display: flex;
      border: 1px solid #d3d4d5;
      top: 20px;
      z-index: 4;
      align-items: center;
      justify-content: center;
    }
  }
}
.menu-name-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu-content-promo {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.menu-content {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.all-time-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all-time-favourite-wrapper {
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  row-gap: 23.19px;

  .item:last-child {
    margin-bottom: 100px;
  }
}

.text-all-time {
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  color: #22262d;
  padding-left: 25px;
}

.text-all-time-category {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--cl-black);
  padding-left: 32px;
  padding-right: 25px;
  @media screen and (max-width: 280px) {
    font-size: 15px;
  }
}

.favourite-listing {
  display: flex;
  align-items: center;
  -moz-column-gap: 20px;
  column-gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 25px;
  padding-bottom: 2px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu-listing-title-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--cl-black);
}

.social-text {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: var(--cl-black);
  text-transform: uppercase;
}

.profile-wrapper {
  position: absolute;
  background-color: #fff;
  padding: 19px;
  border-radius: 10px;
  display: flex;
  border: 1px solid #d3d4d5;
  top: 20px;
  z-index: 4;
  align-items: center;
  justify-content: center;

  &.back-size {
    width: 56px;
    height: 56px;
  }

  .bookmark-text {
    font-size: 16px;
    color: #22262d;
    font-weight: 700;
  }

  &.right {
    right: 20px;
  }

  &.left {
    left: 20px;
  }
}

.button-overlay {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 38, 45, 0.2);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: 10px;

  .icon {
    width: 17.5px;
    height: 17.5px;
  }
}

.restaurant-wallpaper {
  height: 234px;
  position: relative;

  .wallpaper {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.thumbnail-landscape-flex {
  display: flex;
  flex-direction: column;
  margin: 21px 25px;
  row-gap: 10px;
  @media screen and (max-width: 280px) {
    margin: 10px 20px;
  }
}

.thumbnail-horizontal-flex {
  display: flex;
  flex-direction: row;
  margin: 10px 25px;
  row-gap: 10px;
  max-width: 100vw;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.filter-listing-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0%;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: var(--cl-white);

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: var(--cl-txt);
  }

  .menu-category {
    padding: 21px 15px 21px 15px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .filter-listing {
    padding: 25px 15px;
    overflow: scroll;
    max-height: 87vh;
    text-transform: capitalize;
  }

  .item {
    padding: 20px 0;
    font-weight: 400;
    color: var(--cl-txt);
    font-size: 16px;

    &:first-child {
      padding-top: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d3d4d5;
    }
  }
}

.filter-listing-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--cl-bg);
  padding: 30px 15px;

  .filter-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    // text-transform: uppercase;
    color: #22262d;

    .keyword {
      color: var(--cl-primary);
    }
  }

  .filter-listing {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.menu-details {
  .price {
    margin-left: 10px;
  }
}
.filter-container {
  position: absolute;
  bottom: 10px;
  overflow: hidden;
  width: 96% !important;
  z-index: 1050;
  .filter-list {
    width: 100% !important;
    display: flex;
    gap: 17px;
    justify-content: flex-start;
    align-items: center;
    max-width: 90vw;
    font-weight: 500;
    color: #a9a9a9;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
}

.reservation-container {
  display: flex;
  flex-direction: column;
  padding-top: 27%;
  padding: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .reservation-h2 {
    font-size: 25px;
    text-align: center;
  }
  .reservation-box-container {
    background-color: white;
    padding: 2px;
    border-radius: 10px;
    margin-top: 2vw;
    .reservation-box {
      display: flex;
      flex-direction: row;
      background-color: var(--cl-white);
      border-radius: 10px;
      min-height: 90px;
      min-width: 77vw;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .reservation-box-dates-left-container {
        width: 20%;
        min-width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1px;
        align-items: center;
        flex: 1 1 auto;
        border-right: solid 2px #f5f5f5;
        .reservation-box-dates-left-top {
          font-size: 15px;
          color: #a9a9a9;
          font-weight: 450;
        }
        .reservation-box-dates-left-middle {
          font-size: 25px;
          font-weight: 500;
        }
        .reservation-box-dates-left-bottom {
          font-size: 15px;
          font-weight: 450;
          color: #a9a9a9;
        }
      }
      .reservation-box-details-right-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
        flex: 1 1 auto;
        .reservation-box-details-right-container-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .reservation-box-details-right-container-row-name {
            font-weight: 500;
            font-size: 18px;
            color: brandColor;
            text-transform: capitalize;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 65%;
          }
          .reservation-box-details-right-container-row-edit {
            padding-right: 0.5rem;
            color: #a9a9a9;
          }
        }
        .reservation-box-details-right-container-row-guest-number {
          font-size: 13px;
          color: #a9a9a9;
        }
        .reservation-box-details-right-container-row-date {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .reservation-box-details-right-container-row-date-icon {
            max-width: 25vw;
            display: flex;
            flex-direction: row;
            gap: 2px;
            justify-content: flex-start;
            border-radius: 20px;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
.sticky-content {
  position: -webkit-sticky;
  position: sticky;

  &.fix-top {
    top: 0;
  }

  &.fix-top-4 {
    top: 40px;
  }

  &.fix-top-21 {
    top: 21px;
  }

  &.fix-bottom {
    bottom: 0;
  }

  &.fixed {
    &.fix-top {
      -webkit-animation: fixedTop 0.4s;
      animation: fixedTop 0.4s;
      z-index: 1050;
    }
    &.fixed-logo {
      left: 16px !important;
      top: 5px !important;
      -webkit-animation: fixedTop 0.4s;
      animation: fixedTop 0.4s;
    }

    position: fixed;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

@-webkit-keyframes fixedTop {
  0% {
    margin-top: -60px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes fixedTop {
  0% {
    margin-top: -60px;
  }

  100% {
    margin-top: 0;
  }
}
