.confirmation-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  color: #22262D;
}

.position-absolute {
  position: absolute;
  top: -20%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 9999999;
}

.confirmation-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #64676C;
}

.back-review-text {

  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: var(--cl-primary);
}